import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Container, Box } from '@mui/material';

const MainPage = () => {
  return (
    <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: '60px' }}>
        이지 툴은 Copliot을 이용해서 만든 도구를 제공하는 사이트입니다. <br/>AI로 만든 도구들을 만나보세요!
      </Typography>
      <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CardMedia
                component="img"
                image="./calo.png" // Replace with actual image path
                alt="Calo"
                sx={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              />
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Calo
              </Typography>
              <Typography variant="body2" color="text.secondary">
                계산기 프로그램입니다. 여기에는 생활속에서 도움이되는 간단한 계산기를 제공하는 사이트입니다. 현재는 나이 계산기를 제공하고 있습니다.
              </Typography>
            </CardContent>
            <Button size="small" color="primary" href="https://calo.eztool.net">
              Visit Calo
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CardMedia
                component="img"
                image="./cherry.png" // Replace with actual image path
                alt="Cherry"
                sx={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              />
            </Box>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Cherry
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Cherry는 원하는 YouTube 영상에서 핵심 구간만 빠르게 찾아 저장하고, 언제든지 다시 감상할 수 있는 서비스입니다. 나만의 체리를 PICK, STORE, 그리고 PLAY 하세요!
              </Typography>
            </CardContent>
            <Button size="small" color="primary" href="https://cherry.eztool.net">
              Visit Cherry
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainPage;